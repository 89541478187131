.notice {
  background-color: orange;
  color: #fff;
  padding: 3px 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2 !important;
}

.notice p {
  margin: unset;
}

.marquee-container {
  overflow: hidden;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marqueeAnimation 20s linear infinite;
  animation: marqueeAnimation 20s linear infinite !important;

  /* Adjust the duration as needed */
}

@keyframes marqueeAnimation {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
